<template>
  <b-card title="Operation" sub-title="Information about operation">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="Times">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="OP start"
                    label-for="op_start_date"
                    label-cols-md="4"
                  >
                    <flat-pickr
                      v-model="patientInfo.op_start_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'd.m.Y H:i' }"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="OP end"
                    label-for="op_end_date"
                    label-cols-md="4"
                  >
                    <flat-pickr
                      v-model="patientInfo.op_end_date"
                      class="form-control"
                      :config="{ enableTime: true, dateFormat: 'd.m.Y H:i' }"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="12">
                    <b-form-group
                        label="OP start"
                        label-for="op_start_date"
                        label-cols-md="4"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                            id="op_start_date"
                            ref="op_start_date"
                            v-model="patientInfo.op_start_date"
                            type="text"
                            placeholder="DD.MM.YYYY"
                            v-mask="dateMask"
                            autocomplete="off"
                            show-decade-nav
                            :readonly="readOnly"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                              v-model="date1"
                              value-as-date
                              show-decade-nav
                              button-only
                              button-variant="outline-primary"
                              right
                              size="sm"
                              locale="de"
                              aria-controls="example-input"
                              :disabled="readOnly"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                        label="OP end"
                        label-for="op_end_date"
                        label-cols-md="4"
                    >
                      <b-input-group class="mb-1">
                        <b-form-input
                            id="op_end_date"
                            ref="op_end_date"
                            v-model="patientInfo.op_end_date"
                            type="text"
                            placeholder="DD.MM.YYYY"
                            v-mask="dateMask"
                            autocomplete="off"
                            show-decade-nav
                            :readonly="readOnly"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                              v-model="date2"
                              value-as-date
                              show-decade-nav
                              button-only
                              button-variant="outline-primary"
                              right
                              size="sm"
                              locale="de"
                              aria-controls="example-input"
                              :disabled="readOnly"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col> -->
                <b-col cols="12">
                  <b-form-group
                    label="Skin to skin [min]"
                    label-for="interval"
                    label-cols-md="4"
                  >
                    <div class="vertical-align">
                      {{ skin_2_skin }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="MIC technique"
              label-for="intraop_mic_technique"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.intraop_MIC_technique"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Conversion to sternotomy">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_conversion_to_sternotomy"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Reason for conversion"
                    label-for="intraop_reason_for_conversion"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      v-model="patientInfo.intraop_reason_for_conversion"
                      rows="3"
                      placeholder="Reason for conversion"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="Concomitant procedures">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_concomitant_procedures"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Type"
                    label-for="intraop_concomitant_procedures_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.intraop_concomitant_procedures_type"
                      id="intraop_concomitant_procedures_type"
                      placeholder="Type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="ASD PFO closure"
                    label-for="intraop_asd_pfo_closure"
                    label-cols-md="4"
                  >
                    <v-select
                      id="asd_pfo_closure"
                      v-model="patientInfo.intraop_asd_pfo_closure"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="LAA closure"
                    label-for="intraop_laa_closure"
                    label-cols-md="4"
                  >
                    <v-select
                      id="laa_closure"
                      v-model="patientInfo.intraop_laa_closure"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Rhythm ablation therapy"
                    label-for="intraop_rhythm_ablation_therapy"
                    label-cols-md="4"
                  >
                    <v-select
                      id="rhythm_ablation_therapy"
                      v-model="patientInfo.intraop_rhythm_ablation_therapy"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Pacemaker lead extraction"
                    label-for="intraop_pacemaker_lead_extraction"
                    label-cols-md="4"
                  >
                    <v-select
                      id="pacemaker_lead_extraction"
                      v-model="patientInfo.intraop_pacemaker_lead_extraction"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask, convert2NormalDateTime } from "@core/utils/utils";
import { mapState } from "vuex";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        op_start_date: "",
        op_end_date: "",
        intraop_MIC_technique: "",
        intraop_conversion_to_sternotomy: "",
        intraop_reason_for_conversion: "",
        intraop_concomitant_procedures: "",
        intraop_concomitant_procedures_type: "",
        intraop_asd_pfo_closure: "",
        intraop_laa_closure: "",
        intraop_rhythm_ablation_therapy: "",
        intraop_pacemaker_lead_extraction: "",
      },
      value: "",
      options: ["yes", "no"],
      v1: "",
      v2: "",
      date1: null,
      date2: null,
      dateNtim: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    skin_2_skin() {
      if (!this.patientInfo.op_start_date || !this.patientInfo.op_end_date) {
        return "0";
      }
      if (
        this.patientInfo.op_start_date.length == 16 &&
        this.patientInfo.op_start_date.length == 16
      ) {
        let startDate = moment(
          convert2NormalDateTime(this.patientInfo.op_start_date)
        );
        let endDate = moment(
          convert2NormalDateTime(this.patientInfo.op_end_date)
        );
        return endDate.diff(startDate, "minute");
      }
      return "0";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.op_start_date = v.op_start_date;
      this.patientInfo.op_end_date = v.op_end_date;
      this.patientInfo.intraop_MIC_technique = v.intraop_MIC_technique;
      this.patientInfo.intraop_conversion_to_sternotomy =
        v.intraop_conversion_to_sternotomy;
      this.patientInfo.intraop_reason_for_conversion =
        v.intraop_reason_for_conversion;
      this.patientInfo.intraop_concomitant_procedures =
        v.intraop_concomitant_procedures;
      this.patientInfo.intraop_concomitant_procedures_type =
        v.intraop_concomitant_procedures_type;
      this.patientInfo.intraop_asd_pfo_closure = v.intraop_asd_pfo_closure;
      this.patientInfo.intraop_laa_closure = v.intraop_laa_closure;
      this.patientInfo.intraop_rhythm_ablation_therapy =
        v.intraop_rhythm_ablation_therapy;
      this.patientInfo.intraop_pacemaker_lead_extraction =
        v.intraop_pacemaker_lead_extraction;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<template>
  <b-card title="TV" sub-title="Information about TV">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="TV">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_tv"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surgery type"
                    label-for="intraop_op_tv_surgery_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_tv_surgery_type"
                      label="value"
                      :options="options1"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Repair">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Repair annuloplasty"
                    label-for="intraop_op_tv_repair_annuloplasty"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_tv_repair_annuloplasty"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Repair chordae"
                    label-for="intraop_op_tv_repair_chordae"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_tv_repair_chordae"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="Other">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_tv_others"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Explain"
                    label-for="mitraclip"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      v-model="patientInfo.intraop_op_tv_others_explain"
                      rows="3"
                      placeholder="Explain"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Replacement">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis type"
                    label-for="intraop_op_tv_replacement_prosthesis_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_tv_replacement_prosthesis_type
                      "
                      id="intraop_op_tv_replacement_prosthesis_type"
                      placeholder="Prosthesis type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis size"
                    label-for="intraop_op_tv_replacement_prosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_tv_replacement_prosthesis_size
                      "
                      id="intraop_op_tv_replacement_prosthesis_type"
                      type="number"
                      placeholder="Prosthesis size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        intraop_op_tv: "",
        intraop_op_tv_surgery_type: "",
        intraop_op_tv_repair_annuloplasty: "",
        intraop_op_tv_repair_chordae: "",
        intraop_op_tv_others: "",
        intraop_op_tv_others_explain: "",
        intraop_op_tv_replacement_prosthesis_type: "",
        intraop_op_tv_replacement_prosthesis_size: 0,
      },
      options: ["yes", "no"],
      options1: ["TVR open", "TVR MIC", "TV replace open", "TV replace MIC"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.intraop_op_tv = v.intraop_op_tv;
      this.patientInfo.intraop_op_tv_surgery_type =
        v.intraop_op_tv_surgery_type;
      this.patientInfo.intraop_op_tv_repair_annuloplasty =
        v.intraop_op_tv_repair_annuloplasty;
      this.patientInfo.intraop_op_tv_repair_chordae =
        v.intraop_op_tv_repair_chordae;
      this.patientInfo.intraop_op_tv_others = v.intraop_op_tv_others;
      this.patientInfo.intraop_op_tv_others_explain =
        v.intraop_op_tv_others_explain;
      this.patientInfo.intraop_op_tv_replacement_prosthesis_type =
        v.intraop_op_tv_replacement_prosthesis_type;
      this.patientInfo.intraop_op_tv_replacement_prosthesis_size =
        v.intraop_op_tv_replacement_prosthesis_size;
    },
  },
};
</script>

<style>
</style>

<template>
  <b-card title="MV" sub-title="Information about MV">
    <b-row>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="MV surgery">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_mv"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Surgery type"
                    label-for="intraop_op_mv_surgery_type"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_mv_surgery_type"
                      label="value"
                      :options="options1"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Technique"
                    label-for="intraop_op_mv_technique"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.intraop_op_mv_technique"
                      label="value"
                      :options="options2"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Repair">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Annuloplasty"
                    label-for="intraop_op_tv_repair_annuloplasty"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_annuloplasty
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Triangular resection"
                    label-for="intraop_op_mv_surgery_technique_mv_repair_triangular_resection"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_triangular_resection
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Quadrangular resection"
                    label-for="intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Chordae"
                    label-for="intraop_op_mv_surgery_technique_mv_repair_chordae"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_repair_chordae
                      "
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col cols="12">
            <b-card class="bordered-card" title="Other">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_mv_others"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Explain"
                    label-for="mitraclip"
                    label-cols-md="4"
                  >
                    <b-form-textarea
                      v-model="patientInfo.intraop_op_mv_others_explain"
                      rows="3"
                      placeholder="Explain"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="Replacement">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="---" label-for="---" label-cols-md="4">
                    <v-select
                      v-model="patientInfo.intraop_op_mv_replacement"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis type"
                    label-for="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type
                      "
                      id="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type"
                      placeholder="Prosthesis type"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Prosthesis size"
                    label-for="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="
                        patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size
                      "
                      id="intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size"
                      type="number"
                      placeholder="Prosthesis size"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        intraop_op_mv: "",
        intraop_op_mv_surgery_type: "",
        intraop_op_mv_technique: "",
        intraop_op_mv_surgery_technique_mv_repair_annuloplasty: "",
        intraop_op_mv_surgery_technique_mv_repair_triangular_resection: "",
        intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection: "",
        intraop_op_mv_surgery_technique_mv_repair_chordae: "",
        intraop_op_mv_others: "",
        intraop_op_mv_others_explain: "",
        intraop_op_mv_replacement: "",
        intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type: "",
        intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size: 0,
      },
      options: ["yes", "no"],
      options1: [
        "MV repair open",
        "MVR MIC",
        "MV replace open",
        "MV replace MIC",
      ],
      options2: ["MV repair", "MV replacement"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.intraop_op_mv = v.intraop_op_mv;
      this.patientInfo.intraop_op_mv_surgery_type =
        v.intraop_op_mv_surgery_type;
      this.patientInfo.intraop_op_mv_technique = v.intraop_op_mv_technique;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_annuloplasty =
        v.intraop_op_mv_surgery_technique_mv_repair_annuloplasty;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_triangular_resection =
        v.intraop_op_mv_surgery_technique_mv_repair_triangular_resection;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection =
        v.intraop_op_mv_surgery_technique_mv_repair_quadrangular_resection;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_repair_chordae =
        v.intraop_op_mv_surgery_technique_mv_repair_chordae;
      this.patientInfo.intraop_op_mv_others = v.intraop_op_mv_others;
      this.patientInfo.intraop_op_mv_others_explain =
        v.intraop_op_mv_others_explain;
      this.patientInfo.intraop_op_mv_replacement = v.intraop_op_mv_replacement;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type =
        v.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_type;
      this.patientInfo.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size =
        v.intraop_op_mv_surgery_technique_mv_replacement_prosthesis_size;
    },
  },
};
</script>

<style>
</style>
